.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.am-picker {
  .am-picker-header {
    width: 100% !important;
  }

  .am-picker-columns {
    direction: rtl;
  }
}


.required-products-dialog-body {
  padding-bottom: 0 !important;
  padding-top: 8px !important;
  padding-right: 4px !important;
  padding-left: 4px !important;
  .am-dialog-body-message-wrapper {
    width: 100%;
    display: block !important;
  }

  .am-list-item {
    padding-left: 0 !important;
  }

  .am-checkbox {
    margin-left: 6px !important;

    .am-checkbox-content {
      width: calc(100% - 28px);
    }
  }
}

.list-text-align-end {
  .am-list-item-content .am-list-item-content-main {
    text-align: end !important;
  }
}

.hide-hover-effect {
  color: black !important;
  &:hover {
    color: black !important;
  }
}

.order-container {
  .am-list-item-content {
    padding-top: 4px;
    padding-bottom: 4px;
    .am-list-item-description {
      padding-top: 0 !important;
      font-size: 12px !important;
    }
  }

  .product-search {
    .am-search-cancel{
      display: none;
    }
  }

  .ant-input-number {
    width: 50px;
  }
}

input {
  text-align: end;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.red-background {
  background: #f8c2c2 !important;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  padding: 16px;
}

.green-background {
  background: #cfebc5 !important;
  position: absolute;
  height: 100%;
  top: 0;
  padding: 16px;
  left: 0;
}

.antTableTd {
  .ant-table-tbody > tr > td {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
